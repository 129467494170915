import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the reversed word in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="H"
          status="correct"
        />
        <Cell value="G" isCompleted={true} />
        <Cell value="U" isCompleted={true} />
        <Cell value="O" isCompleted={true} />
        <Cell value="T" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter H is in the word and in the correct spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="E" isCompleted={true} />
        <Cell value="V" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="I"
          status="present"
        />
        <Cell value="R" isCompleted={true} />
        <Cell value="D" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter I is in the word but in the wrong spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="N" isCompleted={true} />
        <Cell value="O" isCompleted={true} />
        <Cell value="L" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="M" status="absent" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter M is not in the word in any spot.
      </p>

      {/*<p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
       {' '}
        <a
          href="https://ko-fi.com/wordsgames"
          className="font-bold underline"
        >
          If you like the game don't hesitate to buy me a coffee
        </a>{' '}
  </p>*/}
    </BaseModal>
  )
}
